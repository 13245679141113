import { NoSubtitle } from "assets/images";
import { errorResponse, failResponse, normalEncryptData } from "config/config";
import React, { useRef, useState } from "react";
import { Col, Dropdown, Nav, NavLink, Row, Tab } from "react-bootstrap";
import { BsArrowRight, BsFillCaretDownFill } from "react-icons/bs";
import { MdKeyboardArrowDown } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";

import { getCourseVerticals, getExamTypes } from "services/master";

const ExamMenu = () => {
  const [allExamTypes, setAllExamTypes] = useState([]);
  const [allVerticals, setAllVerticals] = useState([]);
  const [centerVertical, setCenterVertical] = useState({});
  const navigate = useNavigate();

  const [selectedExamType, setSelectedExamType] = useState({
    id: 4,
    title: "",
  });
  const [selectedVertical, setSelectedVertical] = useState({
    id: 4,
    title: "",
  });
  const [onHover, setOnHover] = useState(false);
  const menuRef = useRef();

  // this function used for redirect center based landing page
  // const centreRedirectHandler = (id, center) => {
  //   if (center?.length < 1) {
  //     navigate(
  //       `/${centerVertical?.CenterName}/vertical/${normalEncryptData(
  //         id,
  //         "route"
  //       )}`
  //     );
  //     menuRef.current.classList.remove("active");
  //   }
  // };
  // this function used for redirect center based landing page
const centreRedirectHandler = (id, center, name) => {
  if (center?.length < 1) {
    alert("inside")
    alert(id)
    console.log("center",name)
    navigate(
      //`/IAS_Institute_${centerVertical?.CenterName.replace(/\s+/g, '_')}`
       `/course-listing/verticle/${name}`
    );
    menuRef.current.classList.remove("active");
  }
};


  // --------------- getting all required master data with courses ----------------//
  React.useLayoutEffect(() => {
    // get course exam type
    const getCourseExamType = async () => {
      try {
        const res = await getExamTypes();
        if (res.code === 200) {
          setAllExamTypes(res.data.examType.rows || []);
        } else {
          failResponse(res);
        }
      } catch (err) {
        errorResponse(err);
      }
    };

    // get course verticals
    const getAllCourseVerticals = async () => {
      try {
        const res = await getCourseVerticals();
        if (res.code === 200) {
          // removing upsc for center verticals
          setAllVerticals(
            res.data.courseVerticles.rows.filter(
              (data) => !data.name.toLowerCase().includes("upsc")
            ) || []
          );
          setCenterVertical(res.data.delhiCenter || {});
        } else {
          failResponse(res);
        }
      } catch (err) {
        errorResponse(err);
      }
    };

    // calls all the master functions
    getCourseExamType();
    getAllCourseVerticals();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <li
        className={`has-menu ${onHover ? "active" : ""}`}
        ref={menuRef}
        onMouseEnter={() => setOnHover(true)}
        onMouseLeave={() => setOnHover(false)}
      >
        <NavLink to="" className="nav_link">
          Exams{" "}
          <span className="icon">
            <MdKeyboardArrowDown />
          </span>
        </NavLink>

        <div className="megamenu exam">
          <Tab.Container id="left-tabs-example">
            <Row>
              <Col sm={4} className="menu_left">
                <Nav variant="pills" className="flex-column">
                  {allExamTypes &&
                    allExamTypes.map(({ title, id }) => {
                      return (
                        <Nav.Item
                          key={id}
                          onClick={() => {
                            setSelectedExamType({ id, title });
                            +id !== 4 &&
                              navigate(`/course-listing/exam/${title}/${id}`);
                            menuRef.current.classList.remove("active");
                          }}
                        >
                          <Nav.Link
                            className={`nav_item ${
                              id === selectedExamType.id && "active"
                            }`}
                          >
                            <p>
                              <b>{title}</b>
                            </p>
                          </Nav.Link>
                        </Nav.Item>
                      );
                    })}
                </Nav>
              </Col>

              {/* we are using direct id for course vertical */}
              {selectedExamType?.id === 4 ? (
                <Col sm={8} className="menu_right">
                  <div className="megamenu_content row">
                    <div className="col">
                      <h5>
                        <span className="_title">STATE PSC</span>
                     
                      </h5>
                      <ul className="_span _exam">
                        {allVerticals &&
                          allVerticals.map(({ name, id, centerPages }) => {
                            return (
                              <li key={id} className="">
                                <Dropdown
                                    onClick={() => {
                                      setSelectedVertical({ id, centerPages, name });
                                      +id !== 80 &&
                                        navigate(`/course-listing/verticle/${name}/${id}`);
                                      menuRef.current.classList.remove("active");
                                    }}
                                >
                                  <Dropdown.Toggle
                                    variant="success"
                                    id="dropdown-basic"
                                  >
                                    {name}
                                    {centerPages?.length > 1 && (
                                      <BsFillCaretDownFill fontSize={10} />
                                    )}
                                  </Dropdown.Toggle>
                                  {centerPages?.length > 1 && (
                                    <Dropdown.Menu>
                                      {centerPages?.map((data) => {
                                        return (
                                          <Dropdown.Item key={data.id}>
                                            <Link
                                              onClick={() =>
                                                menuRef.current.classList.remove(
                                                  "active"
                                                )
                                              }
                                              to={`/${data?.center?.City?.replace(
                                                /\s/g,
                                                "-"
                                              )}/center/${normalEncryptData(
                                                data.id,
                                                "route"
                                              )}`}
                                            >
                                              {data?.center?.City}
                                            </Link>
                                          </Dropdown.Item>
                                        );
                                      })}
                                    </Dropdown.Menu>
                                  )}
                                </Dropdown>
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                    <div className="button_wrap">
                      <Link to="/course-listing" className="button">
                        View All Courses
                        <span className="icon">
                          <BsArrowRight />
                        </span>
                      </Link>
                    </div>
                  </div>
                </Col>
              ) : (
                <Col sm={8} className="p-0">
                  <div className="megamenu_content">
                    <div className="image_wrap">
                      <img
                        src={NoSubtitle}
                        alt=""
                        className="w-100"
                        loading="lazy"
                      />
                    </div>
                    <div className="button_wrap pt-5 text-center">
                      <Link to="/course-listing" className="button">
                        View all courses
                        <span className="icon">
                          <BsArrowRight />
                        </span>
                      </Link>
                    </div>
                  </div>
                </Col>
              )}
            </Row>
          </Tab.Container>
        </div>
      </li>
    </>
  );
};

export default ExamMenu;
